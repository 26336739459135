var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { redirectTo } from "admin/utils";
import { Routes } from "@/constants";
import { api } from "../client";
import { getOrganization } from "./user";
export var convertOrganizationInvestment = function (data) {
    var processDeadline = new Date(data.process_deadline).toDateString();
    var secondProcessDeadline = data.second_process_deadline
        ? new Date(data.second_process_deadline).toDateString()
        : "";
    return {
        id: data.id,
        signableInvestmentDocuments: data.signable_investment_documents,
        signedInvestmentDocuments: data.signed_investment_documents,
        processDeadline: processDeadline,
        secondProcessDeadline: secondProcessDeadline,
        status: data.status,
        verifiedOrganization: data.verified_organization,
        fullBankDetails: data.full_bank_details,
        cancelled: data.cancelled,
        productType: data.product_type,
        repaymentDueDays: data.repayment_due_days,
    };
};
export var convertOrganizationShareholder = function (data) {
    return {
        id: data.id,
        witnessName: data.witness_name,
        witnessEmail: data.witness_email,
        witnessAddress: data.witness_address,
        witnessCity: data.witness_city,
        witnessCountry: data.witness_country,
        shareholderOrganization: data.shareholder_organization,
        fullName: data.full_name,
        address: data.address,
        email: data.email,
        dateOfBirth: data.date_of_birth,
        country: data.country,
        city: data.city,
        dataComplete: data.data_complete,
    };
};
export var convertDDSteps = function (data) {
    return data.map(function (d) {
        return {
            id: d.id,
            page: d.page,
            type: d.step_type,
            mandatory: d.mandatory,
            name: d.name,
            status: d.status,
            visible: d.visibility,
            step_attributes: d.step_attributes,
            redirectUrl: d.redirect_url,
            document_upload: d.document_upload,
            description: d.description,
            submittedAt: d.submitted_at,
            processDeadline: "2025-01-01",
        };
    });
};
export var convertDDPages = function (data) {
    return data.map(function (d) {
        return {
            id: d.id,
            name: d.name,
            description: d.description,
            page: d.order,
        };
    });
};
export function getInvestment(params) {
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/investments/" + params.id)];
                case 1:
                    data = (_a.sent()).data;
                    return [2 /*return*/, convertOrganizationInvestment(data)];
            }
        });
    });
}
export function cancelInvestment(_a) {
    var investmentId = _a.investmentId, cancellationReason = _a.cancellationReason, cancellationExplanation = _a.cancellationExplanation;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/investments/" + investmentId + "/cancel", {
                        cancellation_reason: cancellationReason,
                        cancellation_explanation: cancellationExplanation,
                    })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, convertOrganizationInvestment(data)];
            }
        });
    });
}
export function getInvestments(_a) {
    var organizationId = _a.organizationId;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/organizations/" + organizationId + "/investments")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data.map(function (item) { return convertOrganizationInvestment(item); })];
            }
        });
    });
}
export function getApiQuestions(_a) {
    var investmentId = _a.investmentId;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/investments/" + investmentId + "/documents")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, __spreadArrays(data)];
            }
        });
    });
}
export function getApiDDConfiguration(_a) {
    var investmentId = _a.investmentId;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/investments/" + investmentId + "/due_diligence_configuration")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data];
            }
        });
    });
}
export function getApiDDPagesConfiguration(_a) {
    var investmentId = _a.investmentId;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/investments/" + investmentId + "/due_diligence_configuration/pages")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, convertDDPages(data)];
            }
        });
    });
}
export function getApiDDStepsForPage(_a) {
    var investmentId = _a.investmentId, pageId = _a.pageId;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/investments/" + investmentId + "/due_diligence_configuration/pages/" + pageId + "/steps")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, convertDDSteps(data)];
            }
        });
    });
}
export function getOrganizationShareholders(_a) {
    var organizationId = _a.organizationId;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.get("/api/v1/organizations/" + organizationId + "/organization_shareholders")];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, data.map(function (item) { return convertOrganizationShareholder(item); })];
            }
        });
    });
}
export function updateOrganizationShareholder(params) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, api.put("/api/v1/organization_shareholders/" + params.id, {
                        email: params.email,
                        date_of_birth: params === null || params === void 0 ? void 0 : params.dateOfBirth,
                        address: params === null || params === void 0 ? void 0 : params.address,
                        city: params === null || params === void 0 ? void 0 : params.city,
                        country_id: (_a = params.country) === null || _a === void 0 ? void 0 : _a.id,
                        witness_name: params.witnessName,
                        witness_email: params.witnessEmail,
                        witness_address: params.witnessAddress,
                        witness_city: params.witnessCity,
                        witness_country_id: (_b = params.witnessCountry) === null || _b === void 0 ? void 0 : _b.id,
                    })];
                case 1:
                    data = (_c.sent()).data;
                    return [2 /*return*/, convertOrganizationShareholder(data)];
            }
        });
    });
}
export function setUploadDecision(_a) {
    var investmentId = _a.investmentId, uploadDecision = _a.uploadDecision, investmentCategoryId = _a.investmentCategoryId;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/investments/" + investmentId + "/upload_decisions", {
                        upload_decision: uploadDecision,
                        investment_document_category_id: investmentCategoryId,
                    })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function addDocument(_a) {
    var investmentId = _a.investmentId, investmentCategoryId = _a.investmentCategoryId, signedId = _a.signedId;
    return __awaiter(this, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, api.post("/api/v1/investments/" + investmentId + "/documents", {
                        investment_document_category_id: investmentCategoryId,
                        signed_id: signedId,
                    })];
                case 1:
                    data = (_b.sent()).data;
                    return [2 /*return*/, __spreadArrays(data)];
            }
        });
    });
}
export function removeDocument(_a) {
    var _b;
    var investmentId = _a.investmentId, investmentCategoryId = _a.investmentCategoryId, signedId = _a.signedId;
    return __awaiter(this, void 0, void 0, function () {
        var apiQuestions, currentCategory, fileIdToDelete, data;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, getApiQuestions({ investmentId: investmentId })];
                case 1:
                    apiQuestions = _c.sent();
                    currentCategory = apiQuestions.find(function (question) { return question.id === investmentCategoryId; });
                    fileIdToDelete = ((_b = currentCategory === null || currentCategory === void 0 ? void 0 : currentCategory.documents.find(function (document) { return document.signed_id === signedId; })) === null || _b === void 0 ? void 0 : _b.id) || -1;
                    return [4 /*yield*/, api.delete("/api/v1/investments/" + investmentId + "/documents/" + fileIdToDelete)];
                case 2:
                    data = (_c.sent()).data;
                    return [2 /*return*/, __spreadArrays(data)];
            }
        });
    });
}
export var valid_investment_statuses = [
    "due_diligence",
    "kyb",
    "offered",
    "closing",
    "active",
    "recovery",
    "write_off",
    "completed",
];
export function getOrganizationInvestment(_params) {
    return __awaiter(this, void 0, void 0, function () {
        var organization, investments, validInvestments, validInvestment, apiDDPagesConfig, apiDDSteps;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getOrganization()];
                case 1:
                    organization = _a.sent();
                    return [4 /*yield*/, getInvestments({ organizationId: organization.id })];
                case 2:
                    investments = _a.sent();
                    validInvestments = investments.filter(function (validInvestment) {
                        return valid_investment_statuses.includes(validInvestment.status);
                    });
                    if (investments.every(function (investmentDetail) {
                        return investmentDetail.status === "expired" || investmentDetail.status === "cancelled";
                    })) {
                        redirectTo(Routes.PORTFOLIO);
                    }
                    validInvestment = validInvestments[0];
                    return [4 /*yield*/, getApiDDPagesConfiguration({ investmentId: validInvestment.id })];
                case 3:
                    apiDDPagesConfig = _a.sent();
                    return [4 /*yield*/, getApiDDStepsForPage({
                            investmentId: validInvestment.id,
                            pageId: apiDDPagesConfig[0].id,
                        })];
                case 4:
                    apiDDSteps = _a.sent();
                    return [2 /*return*/, {
                            id: validInvestment.id,
                            steps: __spreadArrays(apiDDSteps),
                            last_page: apiDDPagesConfig.length,
                        }];
            }
        });
    });
}
export function updateTermSheetSubmittedAt(step) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.put("/api/v1/due_diligence_steps/" + step.id, {
                        submitted_at: new Date().toISOString(),
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
